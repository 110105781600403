const findSubscription = (subscriptionId, subscriptions) =>
  subscriptions.find((s) => s.id === subscriptionId)

const getAddress = (subscription) => subscription.delivery_address

const getRecentActiveSub = (subscriptions) =>
  subscriptions
    .filter(({ status }) => status === 'ACTIVE') // Filter by active subs only
    .sort((a, b) => b.id - a.id)[0] // Sort by descending ID

const isGift = (tags) => Array.isArray(tags) && tags.includes('is_gift')

export { findSubscription, getAddress, getRecentActiveSub, isGift }
