import { breakpoints, color, font, sizes } from './variables'

// This is our datepicker CSS file.
const datepicker = `
.react-datepicker{
    font-family: ${font.family.body};
    font-size: ${sizes.base}px;
    border: 2px solid ${color.black};
    border-radius: 0;
    width: 100%;
    max-width: 360px;

    @media (min-width: ${breakpoints.tablet}px){
        max-width: 460px;
    }
}

.react-datepicker__header{
    background-color: transparent;
}

.react-datepicker__month-container{
    float: none;
}

.react-datepicker__current-month{
    font-size: ${sizes.base}px;
    font-weight: ${font.weight.medium}
}

.react-datepicker__day-name,
.react-datepicker__day{
    margin: 0;
    height: 40px;
    line-height: 35px;
    width: 40px;
    border-radius: 100%;
    border: 3px solid ${color.white};
    color: ${color.black};
    cursor: pointer;
    font-size: ${sizes.ms}px;
    @media (min-width: ${breakpoints.tablet}px){
        height: 60px;
        line-height: 54px;
        width: 60px;
    }
}

.react-datepicker__day--weekend,
.react-datepicker__day--Tuesday,
.react-datepicker__day--Wednesday,
.react-datepicker__day--Thursday,
.react-datepicker__day--Friday{
    background-color: ${color.allplantsYellow};
}

.react-datepicker__day:hover,
.react-datepicker__day--selected,	
.react-datepicker__day--selected:hover{
    border-radius: 100%;
    background-color: ${color.black};
    color: ${color.white};
}

/* If no excluded dates are received, use defaults and only exclude Monday and Sunday */
.use-default-excluded .react-datepicker__day--Monday,
.use-default-excluded .react-datepicker__day--Sunday,
.react-datepicker__day.react-datepicker__day--disabled{
    cursor: default;
    background-color: transparent;
    color: #ccc;
    pointer-events: none;
}
`

export default datepicker
