import Link from 'next/link'
import Image from 'next/image'
import { GridItem, SimpleGrid } from '@chakra-ui/react'
import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Button } from '@tofu/shared/ui/atoms/button'
import { SiteFooterColumn } from '@tofu/shared/ui/atoms/site-footer-column'
import { SiteFooterHeading } from '@tofu/shared/ui/atoms/site-footer-heading'
import { SiteFooterLink } from '@tofu/shared/ui/atoms/site-footer-link'
import { environment } from '@tofu/shared/constants/environments'
import {
  AllplantsLogo,
  FacebookIcon,
  InstagramIcon,
  TwitterIcon
} from '@tofu/shared/ui/atoms/icons'
import BCorpLogo from '../assets/BCorpLogo.png'
import GTALogo from '../assets/GTALogo.png'
import LivingWageLogo from '../assets/LivingWageLogo.png'
import PlantPowerLogo from '../assets/PlantPowerIcon.png'
import { TSiteFooter } from './site-footer.types'

export const SiteFooter: TSiteFooter = () => {
  const { shop_base_url } = environment

  return (
    <Box backgroundColor='grey.100' pt={10} pb={6}>
      <SimpleGrid
        maxWidth='container.xl'
        columns={[1, 1, 1, 5]}
        margin='auto'
        px={[4, 4, 4, 0]}
        sx={{
          'div:first-of-type': {
            borderBottom: 'none'
          },
          'div:last-of-type': {
            borderRight: 'none',
            borderBottom: 'none'
          }
        }}
      >
        <SiteFooterColumn>
          <Box textAlign={['center', 'center', 'center', 'left']} mb={3}>
            <AllplantsLogo height='32px' />
          </Box>
          <SimpleGrid
            columns={2}
            mt={8}
            rowGap={6}
            columnGap={2}
            display={['none', 'none', 'none', 'grid']}
          >
            <GridItem width='76px'>
              <Image
                src={GTALogo}
                alt='Multi award-winning great taste producer'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
            <GridItem width='76px'>
              <Image
                src={BCorpLogo}
                alt='Certified B Corporation'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
            <GridItem width='76px'>
              <Image
                src={LivingWageLogo}
                alt='London Living Wage Employer'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
            <GridItem width='76px'>
              <Image
                src={PlantPowerLogo}
                alt='Plant Power'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
          </SimpleGrid>
        </SiteFooterColumn>
        <SiteFooterColumn textAlign={['left', 'center', 'center', 'left']}>
          <SiteFooterHeading>Explore</SiteFooterHeading>
          <Link href={`${shop_base_url}/where-to-find-us`} passHref>
            <SiteFooterLink as={'a'}>Where to find us</SiteFooterLink>
          </Link>
          <Link href={`${shop_base_url}/veganuary`} passHref>
            <SiteFooterLink as={'a'}>Veganuary</SiteFooterLink>
          </Link>
          <Link href={`${shop_base_url}/plants-over-ultra-processed`} passHref>
            <SiteFooterLink as={'a'}>Ultra Processed Food</SiteFooterLink>
          </Link>
          <Link href={`${shop_base_url}/sustainability`} passHref>
            <SiteFooterLink as={'a'}>Sustainability</SiteFooterLink>
          </Link>
          <Link href={`${shop_base_url}/not-at-school-dinner`} passHref>
            <SiteFooterLink as={'a'}>(Not at) school dinner</SiteFooterLink>
          </Link>
          <Link href={`${shop_base_url}/referrals?situation=footer`} passHref>
            <SiteFooterLink as={'a'}>Refer a Friend</SiteFooterLink>
          </Link>
          <Link href={`${shop_base_url}/blog`} passHref>
            <SiteFooterLink as={'a'}>Planted blog</SiteFooterLink>
          </Link>
        </SiteFooterColumn>
        <SiteFooterColumn textAlign={['left', 'center', 'center', 'left']}>
          <SiteFooterHeading>Help</SiteFooterHeading>
          <Link href='https://help.allplants.com/' passHref>
            <SiteFooterLink as={'a'}>FAQs</SiteFooterLink>
          </Link>
          <Link href={`${shop_base_url}/contact`} passHref>
            <SiteFooterLink as={'a'}>Contact us</SiteFooterLink>
          </Link>
          <Link href='https://help.allplants.com/en/articles/87' passHref>
            <SiteFooterLink as={'a'}>Terms & privacy</SiteFooterLink>
          </Link>
          {/* 
              If we ever use the Link component from '@tofu/shop/ui/atoms/link', 
              we need to pass the isExternal prop so that the new page can execute the scripts when the page content loads.
          */}
          <Link href={`${shop_base_url}/cookie-policy`} passHref>
            <SiteFooterLink as={'a'}>Cookie policy</SiteFooterLink>
          </Link>
        </SiteFooterColumn>
        <SiteFooterColumn textAlign={['left', 'center', 'center', 'left']}>
          <SiteFooterHeading>About</SiteFooterHeading>
          <Link href='https://hello.allplants.com/careers' passHref>
            <SiteFooterLink as={'a'}>Careers at allplants</SiteFooterLink>
          </Link>
          <Link href='https://allplants2.typeform.com/to/i6ZJpG' passHref>
            <SiteFooterLink as={'a'}>Partnerships</SiteFooterLink>
          </Link>
          <Link href={`${shop_base_url}/press-and-reviews`} passHref>
            <SiteFooterLink as={'a'}>Press & reviews</SiteFooterLink>
          </Link>
          {/* 
              If we ever use the Link component from '@tofu/shop/ui/atoms/link', 
              we need to pass the isExternal prop so that the new page can execute the scripts when the page content loads.
          */}
          <Link href={`${shop_base_url}/student-discount`} passHref>
            <SiteFooterLink as={'a'}>Student discount</SiteFooterLink>
          </Link>
          <Link
            href='https://secure.gocertify.me/at/allplants/key-worker'
            passHref
          >
            <SiteFooterLink as={'a'}>Key workers discount</SiteFooterLink>
          </Link>
          <Link href='https://secure.gocertify.me/at/allplants/senior' passHref>
            <SiteFooterLink as={'a'}>Senior discount</SiteFooterLink>
          </Link>
        </SiteFooterColumn>
        <SiteFooterColumn textAlign={['left', 'center', 'center', 'left']}>
          <SiteFooterHeading>Stay updated</SiteFooterHeading>
          <Link href='https://www.facebook.com/allplants/' passHref>
            <Button as='a' variant='link' mr={4}>
              <FacebookIcon height='40px' width='40px' />
            </Button>
          </Link>
          <Link href='https://twitter.com/allplants' passHref>
            <Button as='a' variant='link' mr={4}>
              <TwitterIcon height='40px' width='40px' />
            </Button>
          </Link>
          <Link href='https://instagram.com/allplants' passHref>
            <Button as='a' variant='link'>
              <InstagramIcon height='40px' width='40px' />
            </Button>
          </Link>
          <SimpleGrid
            columns={4}
            mt={8}
            columnGap={2}
            display={['grid', 'grid', 'grid', 'none']}
          >
            <GridItem width='76px' margin='auto'>
              <Image
                src={GTALogo}
                alt='Multi award-winning great taste producer'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
            <GridItem width='76px' margin='auto'>
              <Image
                src={BCorpLogo}
                alt='Certified B Corporation'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
            <GridItem width='76px' margin='auto'>
              <Image
                src={LivingWageLogo}
                alt='London Living Wage Employer'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
            <GridItem width='76px' margin='auto'>
              <Image
                src={PlantPowerLogo}
                alt='Plant Power'
                height='76px'
                width='76px'
                layout='responsive'
              />
            </GridItem>
          </SimpleGrid>
        </SiteFooterColumn>
      </SimpleGrid>
      <Text
        mt={10}
        textAlign='center'
        textStyle='body-xs'
        color='grey.700'
        fontWeight='book'
      >
        &copy; Copyright {new Date().getFullYear()} Allplants Ltd. All rights
        reserved
      </Text>
    </Box>
  )
}
