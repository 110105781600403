export const getFromMap = <TMapKey, TMapValue>(
  map: Map<TMapKey, TMapValue>,
  key: TMapKey
): TMapValue => {
  if (!map.has(key)) {
    throw new Error(`No value found for key: ${key}`)
  }

  return map.get(key) as TMapValue
}
