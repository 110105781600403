import React from 'react'
import NextLink from 'next/link'
import PropTypes from 'prop-types'
import { forwardRef } from '@chakra-ui/react'

import { trackClick } from '../../utils/analytics'

const Link = forwardRef((props, _ref) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { children, href, isExternal, shallow, as, name, label, ...rest } =
    props

  const isInternalLink = href && href.startsWith('/')

  const addTracking = () => {
    if (label && name) {
      return trackClick(name, { label })
    }
    return null
  }

  if (isInternalLink) {
    return (
      <NextLink href={href} passHref>
        <a href={href} onClick={addTracking} {...rest}>
          {children}
        </a>
      </NextLink>
    )
  }
  return (
    <a href={href} onClick={addTracking} {...rest}>
      {children}
    </a>
  )
})

Link.defaultProps = {
  href: null,
  label: null,
  name: null
}

Link.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string
}

export default Link
