import axios from 'axios'

import { getUuid } from '@tofu/shared/utils/uuid'
import { environment } from '@tofu/shared/constants/environments'

const uuid = getUuid()
const isDevEnv = environment.environment === 'development'

const clientAuth = axios.create({
  baseURL: isDevEnv ? '/api' : environment.api_base_url,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json'
  },
  timeout: 30_000,
  withCredentials: true
})

clientAuth.defaults.headers.common['X-CSRF-TOKEN'] = uuid

clientAuth.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    // If the response to any request is a 401, always assume auth tokens have expired so do logout and reload app.
    if (error?.response?.status === 401) {
      return clientAuth
        .post('auth/logout')
        .then(() => window.location.reload())
        .catch((error_) => {
          throw error_
        })
    }
    return Promise.reject(error)
  }
)

const clientPublic = axios.create({
  baseURL: environment.api_base_url,
  headers: {
    Accept: '*/*',
    'Content-Type': 'application/json'
  },
  timeout: 30_000
})

export { clientAuth, clientPublic }
