import NextErrorComponent from 'next/error'
import { Center } from '@chakra-ui/react'
import { captureException, flush } from '@sentry/nextjs'
import PropTypes from 'prop-types'
import { logger } from '@tofu/shared/utils/sentry'
import { Button } from '@tofu/shared/ui/atoms/button'
import { Box } from '@tofu/shared/ui/atoms/box'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { Text } from '@tofu/shared/ui/atoms/text'
import PageLayout from '@tofu/apps/account/components/layout/page'

const onClickRefreshPage = () => {
  window.location.reload(false)
}

const MyError = ({ statusCode, hasGetInitialPropsRun, err }) => {
  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured

    // Use our custom error logger so we can capture FS recordings
    logger(err)
    // Flushing is not required in this case as it only happens on the client
  }

  return (
    <Box bg='white' color='black'>
      <PageLayout>
        <Center>
          <VStack spacing={3} pt='10vh'>
            <Text
              as='h2'
              textStyle='body-xl'
              fontWeight='bold'
              textAlign='center'
            >
              Oops, it looks like something&apos;s gone wrong!
            </Text>
            <Text as='h4' textStyle='body-lg' textAlign='center'>
              Please refresh the page to try again.
            </Text>

            <Button
              colorScheme='brandYellow'
              onClick={onClickRefreshPage}
              variant='solid'
            >
              Refresh Page
            </Button>
          </VStack>
        </Center>
        <NextErrorComponent statusCode={statusCode} />
      </PageLayout>
    </Box>
  )
}

MyError.propTypes = {
  err: PropTypes.shape({}).isRequired,
  hasGetInitialPropsRun: PropTypes.bool.isRequired,
  statusCode: PropTypes.number.isRequired
}

MyError.getInitialProps = async ({ res, err, asPath }) => {
  const errorInitialProps = await NextErrorComponent.getInitialProps({
    res,
    err
  })

  // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
  // getInitialProps has run
  errorInitialProps.hasGetInitialPropsRun = true

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (err) {
    captureException(err)

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await flush(2000)

    return errorInitialProps
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`)
  )
  await flush(2000)

  return errorInitialProps
}

export default MyError
