import { getError } from '@tofu/apps/account/utils/errors'
import { logger } from '@tofu/shared/utils/sentry'
import { clientAuth } from '@tofu/account/utils/axios-client'

const getCart = async (token) => {
  try {
    const response = await clientAuth.get(`carts/${token}`)
    return response?.data?.data?.[0]
  } catch (error) {
    // Log the error here and re-throw, as we need to remove the cookie in CartProvider if it's an invalid one
    logger(error)
    throw error
  }
}

const updateCart = async (token, data) => {
  try {
    await clientAuth.put(`carts/${token}`, data)
    return
  } catch (error) {
    const { code, error_code, message } = getError(error)
    // Throw discount errors to FE
    const error_ =
      code === 400
        ? error_code
        : new Error(
            `Failed to update cart: ${error_code || message || error?.message}`
          )
    throw error_
  }
}

export { getCart, updateCart }
