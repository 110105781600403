import { forwardRef, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import FocusTrap from 'focus-trap-react'

import { Box } from '@tofu/shared/ui/atoms/box'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import Icon from '@tofu/apps/account/components/common/icon'
import { useModal } from '@tofu/apps/account/providers/modal'
import close from '@tofu/apps/account/icons/close'
import { color, fade } from '@tofu/apps/account/styles/variables'

const Backdrop = styled(Flex)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${color.overlay};
  ${({ active }) =>
    active
      ? `
  z-index: 20;
  max-height: 100vh;
`
      : `
  display: none;
  max-height: 0;
  `};
`

const Container = styled(Flex)`
  max-width: 300px;
  animation: ${fade} 300ms linear;

  @media (min-width: 360px) {
    max-width: 340px;
  }

  @media (min-width: 380px) {
    max-width: 360px;
  }

  @media (min-width: 400px) {
    max-width: 380px;
  }

  @media (min-width: 480px) {
    max-width: 460px;
  }

  @media (min-width: 640px) {
    max-width: 500px;
  }
`

const Close = styled.button`
  border-radius: 100%;
  border: none;
  width: 30px;
  height: 30px;
  position: absolute;
  right: -8px;
  top: -8px;
  background-color: ${color.allplantsYellow}};
  padding-right: 0;
  padding-left: 0;
  text-align: center;
  cursor: pointer;
  z-index: 12;
`

const Modal = forwardRef(
  ({ active, children, handleClose, isDismissable }, ref) => {
    const wrapperRef = useRef(null)
    const { id } = useModal()

    // eslint-disable-next-line consistent-return
    useEffect(() => {
      if (isDismissable) {
        const handleClickOutside = (event) => {
          if (
            wrapperRef.current &&
            !wrapperRef.current.contains(event.target)
          ) {
            handleClose()
          }
        }
        const handleKeyPress = ({ code }) => code === 'Escape' && handleClose()

        // Bind event listener
        document.addEventListener('keyup', handleKeyPress)
        document.addEventListener('mouseup', handleClickOutside)

        return () => {
          // Unbind event listener on clean up
          document.removeEventListener('keyup', handleKeyPress)
          document.removeEventListener('mouseup', handleClickOutside)
        }
      }
    }, [handleClose, isDismissable])

    return (
      <Backdrop
        active={active}
        alignItems='center'
        data-testid='overlay'
        justifyContent='center'
      >
        {active && (
          <FocusTrap>
            <Container
              ref={ref}
              aria-labelledby={id}
              aria-modal='true'
              bg={color.white}
              p={'8px'}
              role='dialog'
              sx={{ position: 'relative', zIndex: 10 }}
            >
              {isDismissable && (
                <Close onClick={handleClose} type='button'>
                  <Icon
                    aria-label='close'
                    fill={color.black}
                    name={close}
                    width={16}
                  />
                </Close>
              )}
              <Box
                ref={wrapperRef}
                maxHeight='80vh'
                overflowX='hidden'
                overflowY='scroll'
              >
                {children}
              </Box>
            </Container>
          </FocusTrap>
        )}
      </Backdrop>
    )
  }
)

Modal.displayName = 'Modal'

Modal.defaultProps = {
  children: null,
  handleClose: null,
  isDismissable: true
}

Modal.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node,
  handleClose: PropTypes.func,
  isDismissable: PropTypes.bool
}

export default Modal
