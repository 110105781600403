import { useRef } from 'react'
import NiceModal, { useModal } from '@ebay/nice-modal-react'

import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/react'

import { environment } from '@tofu/shared/constants/environments'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { LoginIcon, LogoutIcon } from '@tofu/shared/ui/atoms/icons'

import { DrawerMobileMenuItemPrimary } from './drawer-mobile-menu-item-primary'
import { DrawerMobileMenuItemSecondary } from './drawer-mobile-menu-item-secondary'
import { TDrawerMobileMenuProps } from './drawer-mobile-menu.types'
import { DrawerMobileMenuItemPrimaryTitle } from './drawer-mobile-menu-item-primary/drawer-mobile-menu-item-primary-title'
import { DrawerMobileMenuItemPrimarySubtitle } from './drawer-mobile-menu-item-primary/drawer-mobile-menu-item-primary-subtitle'

export const DrawerMobileMenu = NiceModal.create(
  ({ isLoggedIn, nextDelivery }: TDrawerMobileMenuProps) => {
    const modal = useModal()
    const btnRef = useRef<HTMLButtonElement>(null)
    const hasNextDelivery = !!nextDelivery?.id
    const nextDeliveryUrl = `/deliveries/${nextDelivery?.id}`

    return (
      <Drawer
        isOpen={modal.visible}
        placement='left'
        data-testid='mobile-menu'
        onClose={modal.hide}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay onClick={modal.hide} />

        {/* webkit-appearance to remove native styling from iphone 11 down */}
        <DrawerContent
          w='100%'
          sx={{
            'button, a, span': {
              WebkitAppearance: 'none'
            }
          }}
        >
          <DrawerCloseButton onClick={modal.hide} />
          <DrawerHeader boxShadow='base' mb={1} pl={6} fontWeight='medium'>
            Menu
          </DrawerHeader>

          <DrawerBody
            w='100%'
            p={0}
            bg='linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(245,245,245,1) 50%, rgba(245,245,245,1) 100%);'
          >
            <VStack bg='white' spacing={10} mt={8} pb={8}>
              <DrawerMobileMenuItemPrimary
                href='/deliveries'
                onClick={modal.hide}
              >
                <DrawerMobileMenuItemPrimaryTitle>
                  Home
                </DrawerMobileMenuItemPrimaryTitle>
                <DrawerMobileMenuItemPrimarySubtitle>
                  Your allplants snapshot
                </DrawerMobileMenuItemPrimarySubtitle>
              </DrawerMobileMenuItemPrimary>
              {hasNextDelivery && (
                <DrawerMobileMenuItemPrimary
                  href={nextDeliveryUrl}
                  onClick={modal.hide}
                >
                  <DrawerMobileMenuItemPrimaryTitle>
                    Your next delivery
                  </DrawerMobileMenuItemPrimaryTitle>
                  <DrawerMobileMenuItemPrimarySubtitle>
                    Update your next box
                  </DrawerMobileMenuItemPrimarySubtitle>
                </DrawerMobileMenuItemPrimary>
              )}
              <DrawerMobileMenuItemPrimary
                href='/subscriptions'
                onClick={modal.hide}
              >
                <DrawerMobileMenuItemPrimaryTitle>
                  Subscription settings
                </DrawerMobileMenuItemPrimaryTitle>
                <DrawerMobileMenuItemPrimarySubtitle>
                  Edit your subscription settings
                </DrawerMobileMenuItemPrimarySubtitle>
              </DrawerMobileMenuItemPrimary>
              <DrawerMobileMenuItemPrimary href='/impact' onClick={modal.hide}>
                <DrawerMobileMenuItemPrimaryTitle>
                  Your impact
                </DrawerMobileMenuItemPrimaryTitle>
                <DrawerMobileMenuItemPrimarySubtitle>
                  Track your personal impact
                </DrawerMobileMenuItemPrimarySubtitle>
              </DrawerMobileMenuItemPrimary>
              <DrawerMobileMenuItemPrimary href='/details' onClick={modal.hide}>
                <DrawerMobileMenuItemPrimaryTitle>
                  Personal details
                </DrawerMobileMenuItemPrimaryTitle>
                <DrawerMobileMenuItemPrimarySubtitle>
                  Update your payment details
                </DrawerMobileMenuItemPrimarySubtitle>
              </DrawerMobileMenuItemPrimary>
              <DrawerMobileMenuItemPrimary
                href='/referrals'
                onClick={modal.hide}
              >
                <DrawerMobileMenuItemPrimaryTitle>
                  Refer a friend
                </DrawerMobileMenuItemPrimaryTitle>
                <DrawerMobileMenuItemPrimarySubtitle>
                  Save on your next box
                </DrawerMobileMenuItemPrimarySubtitle>
              </DrawerMobileMenuItemPrimary>
            </VStack>
            <VStack bg='grey.100' spacing={6} pt={8} mb={6} alignItems='start'>
              <DrawerMobileMenuItemSecondary
                href='https://help.allplants.com/en/'
                isExternal
                target='_blank'
              >
                Help
              </DrawerMobileMenuItemSecondary>
              <DrawerMobileMenuItemSecondary
                href='https://allplants.com/blog'
                isExternal
                target='_blank'
              >
                Planted Magazine
              </DrawerMobileMenuItemSecondary>
              <DrawerMobileMenuItemSecondary href='https://allplants.com'>
                allplants.com
              </DrawerMobileMenuItemSecondary>
              {isLoggedIn && (
                <DrawerMobileMenuItemSecondary
                  href={`${environment.api_base_url}/auth/logout`}
                  onClick={modal.hide}
                >
                  <>
                    Log Out
                    <LogoutIcon ml={2} />
                  </>
                </DrawerMobileMenuItemSecondary>
              )}
              {!isLoggedIn && (
                <DrawerMobileMenuItemSecondary
                  href='/login'
                  onClick={modal.hide}
                >
                  Log In
                  <LoginIcon ml={2} />
                </DrawerMobileMenuItemSecondary>
              )}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    )
  }
)
