import PropTypes from 'prop-types'
import { createRef } from 'react'
import { Box } from '@tofu/shared/ui/atoms/box'
import { SiteFooter } from '@tofu/account/ui/organisms/site-footer'
import SkipLink from '@tofu/apps/account/components/common/skipLink'
import { Tracking } from '@tofu/apps/account/components/common/tracking'
import Header from '@tofu/apps/account/components/layout/header'
import MainContent from '@tofu/apps/account/components/layout/mainContent'

const PageLayout = ({ children }) => {
  const mainRef = createRef()

  return (
    <Box>
      <SkipLink ref={mainRef} />
      <Header />
      <MainContent>{children}</MainContent>
      <SiteFooter />
      <Tracking />
    </Box>
  )
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default PageLayout
