/* eslint-disable no-nested-ternary */
import flatten from 'lodash/flatten'

const extrasList = ['BREAKFAST', 'SIDE', 'SMOOTHIE', 'TREAT', 'TRAYBAKE']
const mealsList = ['MEAL', 'PIZZA']

const isMeal = (product_type) => mealsList.includes(product_type)

const getNewToOldProducts = (products) => {
  const newProducts = products.filter(({ is_new }) => is_new)

  const oldProducts = products.filter(({ is_new }) => !is_new)

  return [...newProducts, ...oldProducts]
}

const variantSelector = (portionSize, variantType, variants) => {
  // Find the correct variant
  const variant = variants.find(
    (item) =>
      item.portion_size === portionSize && item.variant_type === variantType
  )

  // Return the variant
  return variant || null
}

const mapVariants = (products, portionSize, variantType) =>
  products &&
  products.map((product) => {
    const variant = variantSelector(portionSize, variantType, product.variants)
    return {
      id: product.id,
      variant
    }
  })

const priority = ['AVAILABLE', 'LOW', 'SOLD_OUT']

const orderbyStockSatus = (products, portionSize) => {
  const arr = priority.map(
    (key) =>
      products.filter((product) => {
        const variant = variantSelector(
          portionSize,
          'SUBSCRIPTION',
          product.variants
        )
        return variant && variant.stock_status === key
      }) || null
  )
  return flatten(arr)
}

export {
  extrasList,
  getNewToOldProducts,
  isMeal,
  mapVariants,
  mealsList,
  orderbyStockSatus,
  variantSelector
}
