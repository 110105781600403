/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useToast } from '@chakra-ui/react'

import { getCart, updateCart } from '@tofu/apps/account/services/cart'
import {
  getDiscountFailMessages,
  getError
} from '@tofu/apps/account/utils/errors'
import { formatPrice } from '@tofu/apps/account/utils/numbers'
import { logger } from '@tofu/shared/utils/sentry'

import { useSession } from './session'

const CartContext = createContext({
  cart_json: null,
  deliver_at: null,
  orderId: null,
  recipient: null,
  setDeliverAt: () => {},
  setOrderId: () => {},
  setRecipient: () => {},
  setSubscriptionId: () => {},
  subscriptionId: null
})

const CartProvider = ({ children }) => {
  const [cart_json, setCartData] = useState(null)
  const [deliver_at, setDeliverAt] = useState(null)
  const [orderId, setOrderId] = useState(null)
  const [recipient, setRecipient] = useState(null)
  const [subscriptionId, setSubscriptionId] = useState(null)
  const toast = useToast()

  const session = useSession()
  const { data, removeCookies } = session
  const { cartToken } = data

  const getCartData = async (token) => {
    try {
      const cartData = await getCart(token)
      if (cartData.status === 'COMPLETED') {
        removeCookies('session_cart_token')
        return setCartData(null)
      }
      return setCartData(cartData)
    } catch (error_) {
      const error = getError(error_)
      const { message } = error || {}

      const invalid = [
        'Invalid Cart token',
        'No Cart with token',
        '403 Forbidden'
      ]

      const isInvalidToken = !!invalid.some((string) =>
        message.includes(string)
      )
      return isInvalidToken && removeCookies('session_cart_token')
    }
  }

  const applyDiscount = async (code) => {
    try {
      await updateCart(cartToken, {
        discount_codes: [
          {
            code
          }
        ]
      })
      const result = await getCart(cartToken)
      if (result?.discount_codes?.length > 0) {
        const { amount, discount_type } = result.discount_codes[0]

        const discountText =
          discount_type === 'PERCENTAGE'
            ? `We have applied a ${amount}% discount`
            : `We have taken £${formatPrice(amount)} off your order`

        toast({
          duration: 3000,
          status: 'success',
          description: discountText
        })
      }
      setCartData(result)
    } catch (error) {
      const message = getDiscountFailMessages(error)
      if (message) {
        toast({
          isClosable: true,
          duration: null,
          status: 'error',
          description: message
        })
      } else {
        logger(error)
      }
    }
  }

  useEffect(() => {
    if (cartToken) {
      getCartData(cartToken)
    } else {
      // If cart token is removed, reset cart object.
      setCartData(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartToken]) // TODO: Adding getCartData as a dependency here appears to cause side effects. Intentionally left out for now until can investigate fully.

  return (
    <CartContext.Provider
      value={{
        applyDiscount,
        cart_json,
        deliver_at,
        orderId,
        recipient,
        setDeliverAt,
        setOrderId,
        setRecipient,
        setSubscriptionId,
        subscriptionId,
        updateCart: async (data) => {
          await updateCart(cartToken, data)
          await getCartData(cartToken)
        }
      }}
    >
      {children}
    </CartContext.Provider>
  )
}

CartProvider.defaultProps = {
  children: null
}

CartProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const useCart = () => {
  const cart = useContext(CartContext)
  if (!cart)
    throw new Error('useCart must be used within a CartProvider component')
  return cart
}

export { CartContext, CartProvider, useCart }
