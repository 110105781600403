import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { createRef } from 'react'
import { Box } from '@tofu/shared/ui/atoms/box'

import { DesktopMenu } from '@tofu/account/ui/organisms/desktop-menu'
import { getActiveOrders } from '@tofu/account/utils/orders'

import { useStore } from '@tofu/apps/account/providers/store'
import ResumeSubscriptionBanner from '@tofu/apps/account/components/layout/resumeSub'
import { menuMap } from '@tofu/apps/account/constants/menuMap'

const MainContent = ({ children }) => {
  const mainRef = createRef()
  const { pathname } = useRouter()

  const { orders = [] } = useStore()
  const activeOrders = getActiveOrders(orders)
  const firstActiveOrder = activeOrders[0]

  const displayNav = menuMap[pathname]

  const displayReactivationBanner = !pathname.includes('[subscriptionId]/')

  return (
    <>
      {displayReactivationBanner && <ResumeSubscriptionBanner />}
      <Box minHeight='60vh'>
        {displayNav && (
          <DesktopMenu
            currentPathname={pathname}
            nextDelivery={firstActiveOrder}
            // ideally wrapping this in a Chakra Show component but not able to test it :(
            display={['none', 'none', 'none', 'block']}
          />
        )}
        <Box
          ref={mainRef}
          as='main'
          id='main-content'
          mx='auto'
          tabIndex='-1'
          py={4}
        >
          {children}
        </Box>
      </Box>
    </>
  )
}

MainContent.propTypes = {
  children: PropTypes.node.isRequired
}

export default MainContent
