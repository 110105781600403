import { Box } from '@tofu/shared/ui/atoms/box'
import { Text } from '@tofu/shared/ui/atoms/text'
import { useCart } from '@tofu/apps/account/providers/cart'
import { breakpoints, color, font } from '@tofu/apps/account/styles/variables'
import { isPurchasable } from '@tofu/apps/account/utils/cart'
import { environment } from '@tofu/shared/constants/environments'

import Link from '../common/link'

const getURL = (purchaseable, deliver_at, subscriptionId) => {
  if (purchaseable && deliver_at) {
    return `/subscriptions/${subscriptionId}/restart`
  } else if (purchaseable && !deliver_at) {
    return `/subscriptions/${subscriptionId}/delivery`
  } else {
    return `${environment.shop_base_url}/account/restart/${subscriptionId}`
  }
}
const ResumeSubscriptionBanner = () => {
  const { cart_json, deliver_at, subscriptionId } = useCart()
  const purchaseable = cart_json?.status
    ? isPurchasable(cart_json.status)
    : false

  return (
    <Box bg={color.black}>
      {cart_json && subscriptionId && (
        <Box
          data-test-id='resume-banner'
          maxWidth={`${breakpoints.tablet}px`}
          mx='auto'
          px={4}
          py={2}
        >
          <Text color={color.white}>
            You have some unsaved changes.{' '}
            <Link
              href={getURL(purchaseable, deliver_at, subscriptionId)}
              style={{
                fontWeight: font.weight.medium,
                textDecoration: 'underline'
              }}
            >
              Resume reactivation
            </Link>
          </Text>
        </Box>
      )}
    </Box>
  )
}

export default ResumeSubscriptionBanner
