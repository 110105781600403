/* eslint-disable @typescript-eslint/no-empty-function */
import PropTypes from 'prop-types'
import { useToast } from '@chakra-ui/react'
import { createContext, useContext } from 'react'

import { logger } from '@tofu/shared/utils/sentry'
import { updateCustomer } from '../services/customers'
import { updateOrder } from '../services/orders'
import { updateSubscription } from '../services/subscriptions'
import { getDiscountFailMessages } from '../utils/errors'
import { FeedbackContext } from './feedback'
import { useStore } from './store'

const ActionsContext = createContext({
  updateCustomer: () => {},
  updateOrder: () => {},
  updateSubscription: () => {}
})

const ActionsProvider = ({ children }) => {
  const feedback = useContext(FeedbackContext)
  const { customer, updateCustomerData, updateAllOrders, updateSubscriptions } =
    useStore()
  const toast = useToast()

  const updateCustomerDetails = async (payload, success, fail, onComplete) => {
    try {
      await updateCustomer(customer.id, payload)
      await updateCustomerData(customer.email)
      feedback.add(
        'success',
        success || 'We have successfully updated your details.'
      )
      if (onComplete) {
        onComplete()
      }
    } catch (error) {
      const { error_code, message, user_message } = error
      let errorMessage = fail

      if (message === 'Stripe card error') {
        errorMessage =
          error_code === 'incorrect_zip'
            ? 'Your billing postcode is incorrect. Please try again.'
            : user_message
      } else {
        logger(error)
      }
      feedback.add(
        'error',
        errorMessage || 'We could not update your details. Please try again.'
      )
    }
  }

  const updateOrderById = async (id, payload, success, fail) => {
    try {
      await updateOrder(id, payload)
      await updateAllOrders()

      toast({
        duration: 3000,
        status: 'success',
        description:
          success || 'We have successfully updated your subscription.'
      })
    } catch (error) {
      const { code, error_code } = error
      let message = fail
      if (code === 400) {
        message =
          getDiscountFailMessages(error_code) ||
          'We could not update your subscription. Please try again.'
      } else {
        logger(error)
      }

      toast({
        isClosable: true,
        duration: null,
        status: 'error',
        description: message
      })
    }
  }

  const updateSubscriptionById = async (
    id,
    payload,
    success,
    fail,
    onComplete
  ) => {
    try {
      await updateSubscription(id, payload)
      await updateSubscriptions(customer.id)
      feedback.add(
        'success',
        success || 'We have successfully updated your subscription.'
      )
      if (onComplete) {
        onComplete()
      }
    } catch (error) {
      logger(error)

      const message =
        fail ||
        getDiscountFailMessages(error) ||
        'We could not update your subscription. Please try again.'

      feedback.add('error', message)
    }
  }

  return (
    <ActionsContext.Provider
      value={{
        updateCustomer: (payload, success, fail, onComplete) =>
          updateCustomerDetails(payload, success, fail, onComplete),
        updateOrder: (id, payload, success, fail) =>
          updateOrderById(id, payload, success, fail),
        updateSubscription: (id, payload, success, fail, onComplete) =>
          updateSubscriptionById(id, payload, success, fail, onComplete)
      }}
    >
      {children}
    </ActionsContext.Provider>
  )
}

ActionsProvider.defaultProps = {
  children: null
}

ActionsProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const useActions = () => {
  const actions = useContext(ActionsContext)
  if (!actions)
    throw new Error(
      'useActions must be used within a ActionsProvider component'
    )
  return actions
}

export { ActionsContext, ActionsProvider, useActions }
