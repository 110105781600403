const getError = (err) => err?.response?.data?.errors?.[0] || {}

const getDiscountFailMessages = (error_code) => {
  switch (error_code) {
    case 'discount_invalid':
      return 'This discount code is invalid.'
    case 'discount_not_found':
      return 'This discount code cannot be found.'
    case 'discount_expired':
      return 'This discount code has expired.'
    case 'discount_once_per_customer':
      return 'This discount code can only be used once per customer'
    case 'discount_minimum_spend':
      return 'You have not reached the minimum amount required to use this discount code.'
    case 'discount_new_customers_only':
      return "Sorry, the code won't work with this order."
    case 'discount_valid_on_subscription_only':
      return 'Sorry, this discount code is only valid for subscription orders.'
    case 'discount_valid_on_one_off_only':
      return 'Sorry, this discount code is only valid for one-off orders.'
    default:
      return null
  }
}
export { getDiscountFailMessages, getError }
