import { clientAuth } from '@tofu/account/utils/axios-client'

const getCustomer = async (email) => {
  try {
    const result = await clientAuth.get('customers', { params: { email } })
    return result && result.data && result.data.data && result.data.data[0]
  } catch (error) {
    throw new Error(`Failed to get customer: ${error}`)
  }
}

export const getCustomersMe = async () => {
  try {
    const response = await clientAuth.get(`customers/me`)

    return response?.data?.data?.[0]
  } catch (error) {
    throw new Error(error)
  }
}

const updateCustomer = async (id, payload) => {
  try {
    await clientAuth.put(`customers/${id}`, payload)
    return
  } catch (error) {
    const error_ =
      error?.response?.data?.errors[0]?.message === 'Stripe card error'
        ? error.response.data.errors[0]
        : new Error('Failed update customer')
    throw error_
  }
}

export { getCustomer, updateCustomer }
