// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { client } from '@tofu/shared/data-access/prismic'
import {
  TGlobalAccountSettings,
  TGetGlobalSettingsAccount
} from './get-global-account-settings.types'

export const getGlobalAccountSettings =
  async (): Promise<TGetGlobalSettingsAccount> => {
    const response: TGlobalAccountSettings = await client.getSingle(
      'global_settings_account',
      {}
    )

    return {
      default_promo_banner: {
        text: response?.data?.default_promo_banner
      }
    }
  }
