import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Link from '@tofu/apps/account/components/common/link'
import {
  buttons,
  color,
  easing,
  font,
  sizes
} from '@tofu/apps/account/styles/variables'

const Button = ({ children }) => <button type='button'>{children}</button>

Button.propTypes = {
  children: PropTypes.node.isRequired
}

const ButtonCTA = styled(Link)`
  font-weight: ${font.weight.medium};
  text-decoration: none;
  color: ${color.black};
  padding: ${sizes.sm}px ${sizes.base}px;
  display: inline-block;
`

const ButtonIcon = styled.button`
  background-color: transparent;
  border: none;
  line-height: 1;
  padding: 0;
`

const ButtonSolid = styled(Link)`
  line-height: 1;
  font-weight: ${font.weight.bold};
  font-size: ${({ variant }) =>
    variant ? variant.fontSize : `${sizes.base}px`};
  text-decoration: none;
  color: ${color.black};
  background-color: ${color.allplantsYellow};
  padding: ${sizes.sm}px ${sizes.ms}px;
  text-transform: uppercase;
  text-align: center;
  min-width: ${({ variant }) => (variant ? variant.minWidth : 100)}px;
  border: ${buttons.border};
  display: block;
  @media (hover: hover) {
    &:hover,
    &:focus {
      background-color: ${({ disabled }) =>
        disabled ? color.lighterGrey : color.black};
      color: ${color.white};
    }
  }
  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${color.lighterGrey};
    &:hover{
      cursor: default;
      background-color: ${color.lighterGrey};
      color: ${color.black};
    }
`};
`

const ButtonSecondary = styled(ButtonSolid)`
  background-color: ${({ disabled }) =>
    disabled ? color.lighterGrey : color.white};
  @media (hover: hover) {
    &:hover {
      color: ${({ disabled }) => (disabled ? color.black : color.white)};
    }
  }
`

const ButtonOutline = styled(ButtonCTA)`
  border: 2px solid ${color.white};
  transition: ${easing.hover};
  background-color: ${color.white};
  &:hover {
    background-color: ${color.sunrise};
  }
`

const ButtonCenter = styled(ButtonSolid)`
  display: block;
  margin: 0 auto;
`

const ButtonText = styled(Link)`
  cursor: pointer;
  ${buttons.buttonText}
`

const TextLink = styled.button`
  display: inline-block;
  cursor: pointer;
  font-weight: ${font.weight.medium};
  margin-bottom: 0;
  background: none;
  border: 0;
  line-height: 1.5;
  padding: 0;
  &:after {
    content: '';
    display: block;
    position: relative;
    top: -4px;
    height: 1px;
    width: 100%;
    border-bottom: 2px solid ${color.black};
  }
`

export {
  Button,
  ButtonCTA,
  ButtonIcon,
  ButtonOutline,
  ButtonSecondary,
  ButtonSolid,
  ButtonCenter,
  ButtonText,
  TextLink
}
