import Cookies from 'js-cookie'

const getSiteCookies = () => {
  const admin = Cookies.get('session_admin')
  const cartToken = Cookies.get('session_cart_token')
  const userId = Cookies.get('session_hp')
  const giftOrder = Cookies.get('session_gift_order')

  return {
    ...(admin && { admin }),
    ...(cartToken && { cartToken }),
    ...(userId && { userId }),
    ...(giftOrder && { giftOrder })
  }
}

export default getSiteCookies
