import { clientAuth } from '@tofu/account/utils/axios-client'

const getSubscriptions = async (customer_id) => {
  try {
    const result = await clientAuth.get(
      `customers/${customer_id}/subscriptions?status=active,cancelled`
    )
    return result && result.data && result.data.data
  } catch (error) {
    throw new Error(`Failed to get subscriptions ${error}`)
  }
}

const updateAddress = async (id, data) => {
  try {
    await clientAuth.put(`subscriptions/${id}/address`, data)
    return
  } catch (error) {
    throw new Error(`Failed to update address ${error}`)
  }
}

const updateSubscription = async (id, data) => {
  try {
    await clientAuth.put(`subscriptions/${id}`, data)
    return
  } catch (error) {
    throw new Error(`Failed to update subscriptions ${error}`)
  }
}

export { getSubscriptions, updateAddress, updateSubscription }
