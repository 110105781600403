import { PulsingText } from '@tofu/shared/ui/atoms/pulsing-text'
import { useGlobalAccountContent } from '@tofu/account/hooks/use-global-account-content'
import { PromoBanner } from '@tofu/shared/ui/atoms/promo-banner'
import { TAccountPromoBanner } from './account-promo-banner.types'
import { renderBannerRichText } from './account-promo-banner.utils'

export const AccountPromoBanner: TAccountPromoBanner = () => {
  const { defaultPromoBanner } = useGlobalAccountContent()

  /* istanbul ignore next */
  const { text } = defaultPromoBanner || {}

  const renderedBannerText = renderBannerRichText(
    text,
    'default_promo_banner_text'
  )

  if (!renderedBannerText) return null

  if (renderedBannerText?.length === 1) {
    return (
      <PromoBanner colorScheme='blackAlpha'>{renderedBannerText}</PromoBanner>
    )
  }

  return (
    <PromoBanner colorScheme='blackAlpha' data-testid='pulsing-banner-text'>
      <PulsingText>{renderedBannerText}</PulsingText>
    </PromoBanner>
  )
}
