import get from 'lodash/fp/get'
import head from 'lodash/fp/head'
import overSome from 'lodash/fp/overSome'
import equals from 'lodash/fp/equals'
import flow from 'lodash/fp/flow'
import sortBy from 'lodash/fp/sortBy'
import uniqBy from 'lodash/fp/uniqBy'
import filter from 'lodash/fp/filter'

import { TGetNextChargeAtFromOrder, TOrderDiscountCode } from './orders.types'

const getStatus = get('status')
const getDiscountCodes = get('discount_codes')
const isPendingCharge = equals('PENDING_CHARGE')
const isPendingFulfilment = equals('PENDING_FULFILMENT')
const getSubscription = get('subscription')
const getNextChargeAt = get('next_charge_at')

export const getNextChargeAtFromOrder: TGetNextChargeAtFromOrder = flow(
  getSubscription,
  getNextChargeAt
)

export const isStatusPendingCharge = flow(getStatus, isPendingCharge)
export const isStatusPendingFulfilment = flow(getStatus, isPendingFulfilment)

export const isStatusActive = overSome([
  isStatusPendingCharge,
  isStatusPendingFulfilment
])

const mergeSubscriptionsById = uniqBy('subscription_id')
const filterActiveOrders = filter(isStatusActive)
const sortByDeliveryDate = sortBy(['deliver_at'])

export const getActiveOrders = flow(
  filterActiveOrders,
  sortByDeliveryDate,
  mergeSubscriptionsById
)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const orderDiscountCode: TOrderDiscountCode = flow(
  getDiscountCodes,
  head
)
