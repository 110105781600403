import { forwardRef } from 'react'
import styled from 'styled-components'

import { VisuallyHidden } from '@tofu/apps/account/styles/accessibleComponents'
import { color, sizes } from '../../styles/variables'

const HiddenUntilFocused = styled(VisuallyHidden)`
  background-color: ${color.allplantsYellow};
  display: block;
  padding: ${sizes.xs}px ${sizes.sm}px;
  text-decoration: underline;
  width: 100%;

  &:focus {
    clip: auto;
    clip-path: none;
    height: auto;
    overflow: visible;
    position: static;
    white-space: inherit;
    width: auto;
  }
`

const SkipLink = forwardRef((props, ref) => (
  <HiddenUntilFocused
    as='a'
    href='#main-content'
    onClick={() => ref.current.focus()}
  >
    Skip to main content
  </HiddenUntilFocused>
))

SkipLink.displayName = 'SkipLink'

export default SkipLink
