/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import Modal from '@tofu/apps/account/components/common/modal'
import { useStore } from '@tofu/apps/account/providers/store'

const ModalContext = createContext({
  add: () => {},
  id: '',
  remove: () => {}
})

const ModalProvider = ({ children }) => {
  const [isDismissable, setDismissable] = useState(true)
  const [modal, setModal] = useState(null)
  const [toggle, setToggle] = useState(false)
  const { subscriptions } = useStore()

  const handleAdd = (component, dismissable) => {
    setModal(component)
    setToggle(true)
    setDismissable(dismissable)
  }

  useEffect(() => {
    if (subscriptions.length > 0) {
      setModal(null)
      setToggle(false)
    }
  }, [subscriptions])

  return (
    <ModalContext.Provider
      value={{
        add: handleAdd,
        id: 'current',
        remove: () => setToggle(false)
      }}
    >
      {children}
      <Modal
        active={toggle}
        handleClose={() => setToggle(false)}
        isDismissable={isDismissable}
      >
        {modal}
      </Modal>
    </ModalContext.Provider>
  )
}

ModalProvider.propTypes = {
  children: PropTypes.node.isRequired
}

const useModal = () => {
  const modal = useContext(ModalContext)
  if (!useModal)
    throw new Error('useModal must be used within a ModalProvider component')
  return modal
}

export { ModalContext, ModalProvider, useModal }
