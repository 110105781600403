const menuMap = {
  '/deliveries': 'Home',
  '/deliveries/[orderId]': 'Next delivery',
  '/subscriptions': 'Subscriptions',
  '/impact': 'Impact',
  '/referrals': 'Refer a friend',
  '/details': 'My details'
}

// eslint-disable-next-line import/prefer-default-export
export { menuMap }
