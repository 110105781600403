/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'

import getSiteCookies from '../utils/cookies'
import { isDevEnv } from '../utils/helpers'

const SessionContext = createContext({
  data: null,
  removeCookies: () => {},
  setCookies: () => {}
})

const defaultOptions = {
  ...(!isDevEnv && { domain: '.allplants.com' }),
  expires: 30
}

const defaultPath = {
  ...(!isDevEnv && { domain: '.allplants.com' }),
  path: ''
}

const SessionProvider = ({ children }) => {
  const [session, setSession] = useState(null)

  useEffect(() => {
    if (navigator?.cookieEnabled) {
      const cookies = getSiteCookies()
      setSession(cookies)
    }
  }, [])

  const handleRemoveCookies = (name, options = defaultPath) => {
    Cookies.remove(name, options)
    const cookies = getSiteCookies()
    setSession(cookies) // Reset session state again after we've added a new cookie
  }

  const handleSetCookies = (name, value, options = defaultOptions) => {
    Cookies.set(name, value, options)
    const cookies = getSiteCookies()
    setSession(cookies) // Reset session state again after we've added a new cookie
  }

  const val = useMemo(
    () => ({
      data: session,
      removeCookies: (name) => handleRemoveCookies(name),
      setCookies: (name, value) => handleSetCookies(name, value)
    }),
    [session]
  )

  return (
    <SessionContext.Provider value={val}>{children}</SessionContext.Provider>
  )
}

SessionProvider.defaultProps = {
  children: PropTypes.any
}

SessionProvider.propTypes = {
  children: PropTypes.element
}

const useSession = () => {
  const session = useContext(SessionContext)
  return session
}

export { SessionContext, SessionProvider, useSession }
