/* eslint-disable no-nested-ternary */
import flatten from 'lodash/flatten'
/**
 * This library sends events through google tag manager
 * @method Send methods create a new event for any trigger
 * @method Track methods are useful for analytics and will be
 * automatically passed on to the analytics platforms (e.g. Amplitude)
 * with the correct name and data
 */

const prefixObject = (obj, prefix) => {
  if (!prefix) return obj
  const prefixObj = {}
  // eslint-disable-next-line no-return-assign
  for (const key of Object.keys(obj)) prefixObj[`${prefix}${key}`] = obj[key]
  return prefixObj
}

const addData = (content) => {
  if (window.dataLayer) {
    try {
      window.dataLayer.push(content)
    } catch (error) {
      throw new Error('Failed to add data for GTM:', error)
    }
  }
}

const sendEvent = (eventName, content, doFlatten, prefix) => {
  if (window.dataLayer) {
    try {
      const processedData = doFlatten
        ? flatten(content, { delimiter: '_' })
        : content
      const data = {
        ...prefixObject(processedData, prefix),
        event: eventName
      }
      window.dataLayer.push(data)
    } catch (error) {
      throw new Error(`Failed to send event ${eventName} for GTM:`, error)
    }
  }
}

const trackEvent = (name, data, permanentData) => {
  addData({ log_data: undefined }) // reset data
  sendEvent('log', {
    log_name: name,
    log_data: data,
    log_perm_data: permanentData
  })
}

const trackClick = (name, data) => {
  trackEvent('button.click', {
    ...data,
    name
  })
}

export { addData, sendEvent, trackClick, trackEvent }
