import PropTypes from 'prop-types'
import styled from 'styled-components'

import Link from '@tofu/apps/account/components/common/link'
import { font } from '@tofu/apps/account/styles/variables'
import config from '@tofu/apps/account/constants/env'

const MenuLink = styled(Link)`
  text-decoration: none;
  font-weight: ${font.weight.medium};
  text-transform: uppercase;
`

const MenuDesktop = ({ isLoggedIn }) => (
  <>
    <li>
      <MenuLink
        href={`${config.shop_base_url}/blog`}
        isExternal
        target='_blank'
      >
        Planted Magazine
      </MenuLink>
    </li>
    <li>
      <MenuLink href='https://help.allplants.com/en' isExternal target='_blank'>
        Help
      </MenuLink>
    </li>
    <li>
      <MenuLink href={`${config.account_base_url}`}>My Account</MenuLink>
    </li>
    <li>
      <MenuLink
        href={isLoggedIn ? `${config.api_base_url}/auth/logout` : '/login'}
      >
        {isLoggedIn ? 'Logout' : 'Login'}
      </MenuLink>
    </li>
  </>
)

MenuDesktop.defaultProps = {
  isLoggedIn: false
}

MenuDesktop.propTypes = {
  isLoggedIn: PropTypes.bool
}

export default MenuDesktop
