import styled from 'styled-components'
// eslint-disable-next-line import/namespace
import { useModal } from '@ebay/nice-modal-react'
import { HamburgerIcon } from '@chakra-ui/icons'

import { Box } from '@tofu/shared/ui/atoms/box'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import { ButtonIcon } from '@tofu/apps/account/components/common/button'
import Link from '@tofu/apps/account/components/common/link'
import { AccountPromoBanner } from '@tofu/account/ui/organisms/account-promo-banner'
import { getActiveOrders } from '@tofu/account/utils/orders'
import MenuDesktop from '@tofu/apps/account/components/layout/menuDesktop'
import { useSession } from '@tofu/apps/account/providers/session'
import { useStore } from '@tofu/apps/account/providers/store'
import { breakpoints, color, sizes } from '@tofu/apps/account/styles/variables'
import config from '@tofu/apps/account/constants/env'
import { Text } from '@tofu/shared/ui/atoms/text'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { AllplantsLogo } from '@tofu/shared/ui/atoms/icons'
import { DrawerMobileMenu } from '@tofu/account/ui/molecules/drawer-mobile-menu'

const LogoContainer = styled.div`
  position: absolute;
  margin: 0 auto;
  top: 16px;
  left: 0;
  right: 0;
  width: 120px;
  @media (min-width: ${breakpoints.tablet}px) {
    position: relative;
    margin: 0;
    top: 3px;
    width: 140px;
  }
`

const MenuButton = styled(ButtonIcon)`
  display: none;
  @media (max-width: ${breakpoints.desktop}px) {
    display: block;
  }
`

const Desktop = styled.ul`
  display: block;
  margin: 0;
  padding-left: 0;
  li {
    display: inline-block;
    padding: 0 ${sizes.ms}px;
  }
  @media (max-width: ${breakpoints.desktop}px) {
    display: none;
  }
`

const Header = () => {
  const session = useSession()
  const { admin, userId } = session.data || {}
  const { customer, orders = [] } = useStore()
  const activeOrders = getActiveOrders(orders)
  const firstActiveOrder = activeOrders[0]
  const drawerMobileMenu = useModal(DrawerMobileMenu)

  const onClickOpenMobileMenu = () => {
    drawerMobileMenu.show({
      isLoggedIn: !!userId,
      nextDelivery: firstActiveOrder
    })
  }

  return (
    <>
      {admin && customer?.email ? (
        <Box
          bg={color.allplantsYellow}
          fontSize={sizes.base}
          p={2}
          textAlign='center'
        >
          Admin warning. You are currently logged in as{' '}
          <strong>{customer.email}</strong>
        </Box>
      ) : (
        <AccountPromoBanner />
      )}

      <Flex
        as='header'
        justifyContent='space-between'
        px={4}
        py={[4, 4, 2, 2]}
        sx={{
          borderBottom: `1px solid ${color.borderLight}`,
          position: 'relative'
        }}
        width={'full'}
      >
        <MenuButton aria-label='Open menu' onClick={onClickOpenMobileMenu}>
          <VStack spacing={-1}>
            <HamburgerIcon width='30px' height='30px' />
            <Text aria-hidden as='span' textStyle='body-xs'>
              Menu
            </Text>
          </VStack>
        </MenuButton>
        <LogoContainer>
          <Link
            aria-label='allplants'
            href={config.shop_base_url}
            style={{ display: 'block' }}
          >
            <AllplantsLogo
              aria-hidden
              data-testid='allplants-logo'
              height='39px'
              width='139px'
              maxWidth='full'
              mb={2}
            />
          </Link>
        </LogoContainer>
        <Flex alignItems='center' as='nav'>
          <Desktop>
            <MenuDesktop isLoggedIn={!!userId} />
          </Desktop>
        </Flex>
      </Flex>
    </>
  )
}

export default Header
