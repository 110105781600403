import { keyframes } from 'styled-components'

const sizes = {
  xxs: 4,
  xs: 8,
  smr: 12,
  sm: 14,
  ms: 16,
  base: 18,
  md: 22,
  lg: 26,
  xl: 30,
  xxl: 34,
  xxxl: 48,
  xxxxl: 72
}

const breakpoints = {
  largeDesktop: 1200,
  desktop: 992,
  tablet: 767,
  phablet: 480,
  phone: 320
}

const color = {
  // Brand colours T3 19
  allplantsYellow: '#FDD902',
  aubergine: '#5e4563',
  blueberry: '#092A50',
  kale: '#05574A',
  mushroom: '#E8E3D9',
  pumpkin: '#D74C10',
  strawbMilkshake: '#FFA7A7',
  mint: '#B1D0CA',
  lightBlueberry: 'rgba(33, 56, 82, 0.1)',
  chickpea: '#F8E8C4',

  // Alerts
  warning: '#FF9719',
  warningLight: '#FFF4AD',

  // Challenge
  challengeOrange: '#D74C10',

  // Other
  page: '#ffffff',
  overlay: 'rgba(0,0,0,0.6)',
  background: '#f7f7f7',
  backgroundGrey: '#F5F5F5',
  backgroundLightGrey: '#F5F5F5',
  backgroundActive: '#e9e9e9',
  lightText: '#747678',
  text: '#26292c',
  accent: '#cbcccd',
  accentComplementary: '#26292c',
  border: '#cbcccd',
  borderLight: '#e5e5e5',
  primary: '#FADB7E',
  primaryComplementary: 'black',
  orange: '#FFA500',
  yellow: '#FADB7E',
  lightYellow: '#FCEFCD',
  disabled: '#898B8C',
  grey: '#aeafb0',
  dark: '#202225',
  darkComplementary: '#404346',
  faint: '#b9babb',
  red: '#f94839',
  redComplementary: '#FFA7A7',
  lightGrey: '#CFD0CB',
  lighterGrey: '#E7E7E5',
  lightGreen: '#6CDEA6',
  darkGrey: '#6D6D6D',
  lighterGreen: '#99cc99',
  paleYellow: '#fef8e5',
  black: '#000000',
  white: '#FFFFFF',
  blue: '#4372A7',
  lightBlue: '#BAD7F7',
  button: {
    default: '#000000',
    active: '#FADB7E',
    disabled: '#CFD0CB',
    hover: '#fadb7e73',
    outline: '#5e9ed6'
  },
  link: {
    default: '#111111'
  },
  input: {
    default: '#111111',
    active: '#0D67DE',
    hover: '#0D67DE',
    focused: '#0D67DE',
    complete: '#33557E',
    disabled: '#898B8C',
    optionHover: '#007eff38',
    text: '#747678',
    activeText: '#26292c',
    placeholder: '#A0A098'
  }
}

const themes = {
  allplantsYellow: {
    bgColor: color.allplantsYellow,
    title: color.black,
    text: color.black,
    background: 'Blueberry-Mushroom',
    backgroundSize: {
      mobile: '50%, 30%',
      desktop: '18% 12%'
    },
    backgroundPosition: {
      mobile: 'left bottom, right 10%',
      desktop: 'left bottom, right center'
    }
  },
  aubergine: {
    bgColor: color.aubergine,
    title: color.mushroom,
    text: color.white,
    background: 'Aubergine-Mushroom',
    backgroundSize: {
      mobile: '0, 40%',
      desktop: '0, contain'
    },
    backgroundPosition: {
      mobile: 'left top, right bottom',
      desktop: 'left top, right 10%'
    }
  },
  blueberry: {
    bgColor: color.blueberry,
    title: color.strawbMilkshake,
    text: color.white,
    background: 'Blueberry-Mushroom',
    backgroundSize: {
      mobile: '50%, 30%',
      desktop: '18%, 12%'
    },
    backgroundPosition: {
      mobile: 'left bottom, right 10%',
      desktop: 'left bottom, right center'
    }
  },
  kale: {
    bgColor: color.kale,
    title: color.mushroom,
    text: color.white,
    background: 'Kale-Strawb',
    backgroundSize: {
      mobile: '6%',
      desktop: '3%'
    },
    backgroundPosition: {
      mobile: '5% 35%, 95% 35%',
      desktop: '5% 35%, 95% 35%'
    }
  },
  strawbMilkshake: {
    bgColor: color.strawbMilkshake,
    title: color.aubergine,
    text: color.black,
    background: 'Strawb-Mushroom',
    backgroundSize: {
      mobile: '50%, 30%',
      desktop: '18%, 12%'
    },
    backgroundPosition: {
      mobile: 'left bottom, right 10%',
      desktop: 'left bottom, right center'
    }
  },
  monochrome: {
    title: color.black,
    text: color.black
  },
  inverted: {
    title: color.white,
    text: color.white
  },
  disabled: {
    bgColor: color.lighterGrey
  },
  challenge: {
    title: color.challengeOrange,
    text: color.black
  }
}

const variants = {
  sm: {
    minWidth: 50,
    fontSize: `${sizes.ms}px`
  },
  md: {
    minWidth: 150,
    fontSize: `${sizes.md}px`
  }
}

const easing = {
  hover: 'all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1) 0s',
  default: '0.6s ease-in 0s'
}

const transitions = {
  offset: '100px',
  delay: 300
}

const font = {
  family: {
    body: `stolzl, sans-serif`,
    heading: `recent-grotesk, sans-serif`
  },
  weight: {
    regular: 400,
    medium: 500,
    bold: 700
  },
  lineheights: {
    body: 1.5,
    heading: 1.1,
    blogPost: 1.6
  }
}

const buttons = {
  width: {
    lg: '260px'
  },
  border: `2px solid ${color.black}`,
  buttonText: `display: inline-block;
    font-weight: ${font.weight.medium};
    &:after {
      content: '';
      display: block;
      position: relative;
      top: -4px;
      height: 1px;
      width: 100%;
      border-bottom: 2px solid currentColor; 
    }`
}

const fade = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export {
  breakpoints,
  buttons,
  color,
  easing,
  fade,
  font,
  sizes,
  themes,
  transitions,
  variants
}
