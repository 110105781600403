import Script from 'next/script'
import { useRouter } from 'next/router'

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from '@tofu/shared/constants/environments'

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useStore } from '@tofu/apps/account/providers/store'

import {
  encodeToLower, // would be good to have this shared but no idea where to put it :-/
  buildScript,
  getFirstValueFromArray
} from './inline-scripts.utils'

import { TInlineScripts } from './inline-scripts.types'

const { mentionme_url } = environment

export const InlineScripts: TInlineScripts = () => {
  const router = useRouter()
  const { customer } = useStore()

  const scriptsParam = router?.query?.['script']

  if (!customer || !scriptsParam || !mentionme_url) return null

  // We've not moved the customer store over to tofu yet
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { email, first_name, last_name } = customer
  const encodedEmail = encodeToLower(email)

  // get the first value of params incase its an array
  const scriptName = getFirstValueFromArray(scriptsParam)

  const src = buildScript(scriptName, {
    mentionme_url,
    first_name,
    last_name,
    email: encodedEmail
  })

  return src ? <Script src={src} /> : null
}
