import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import allplantsLogo from '../../icons/allplantsLogo'
import Icon from './icon'

const flicker = keyframes`
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const Wrapper = styled(Flex)`
  animation: ${flicker} 2s ease-in-out infinite;
`

const Loader = () => (
  <Wrapper
    alignItems='center'
    height='100vh'
    justifyContent='center'
    width='full'
  >
    <Icon
      fill='black'
      height={39}
      name={allplantsLogo}
      style={{ maxWidth: '100%' }}
      width={139}
    />
  </Wrapper>
)

Loader.propTypes = {}

export default Loader
