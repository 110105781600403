import { getError } from '@tofu/apps/account/utils/errors'
import { logger } from '@tofu/shared/utils/sentry'
import { clientAuth } from '@tofu/account/utils/axios-client'

const createOrder = async (payload) => {
  try {
    const response = await clientAuth.post('orders', payload)
    return response?.data?.data?.[0]
  } catch (error) {
    const err = getError(error)
    if (err?.code !== 400) {
      logger(`Failed to create order: ${error}`)
    }
    throw err
  }
}

const getOrdersBySubscription = async (subsciptionId, min_deliver_at) => {
  try {
    const response = await clientAuth.get(
      `subscriptions/${subsciptionId}/orders`,
      {
        params: { min_deliver_at }
      }
    )
    return response?.data?.data
  } catch (error) {
    const { error_code, message } = getError(error)

    throw new Error(`Failed fetch orders: ${error_code || message}`)
  }
}

const updateOrder = async (id, payload) => {
  try {
    await clientAuth.put(`orders/${id}`, payload)
    return
  } catch (error) {
    const err = getError(error)
    const { code, error_code, message } = err

    // If it's a 400, throw the error from Core so we can bubble up the message to FE
    const error_ =
      code === 400
        ? err
        : new Error(`Failed update order: ${error_code || message}`)
    throw error_
  }
}

export { createOrder, getOrdersBySubscription, updateOrder }
