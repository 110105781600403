// This is our datepicker CSS file.
const mentionme = `
    #mmWrapper {
        position: relative;
    }

    .mmWrapping,
    #mmLink{
        position: absolute !important;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        color: transparent;
    }
`

export default mentionme
