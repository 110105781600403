import Script from 'next/script'

import { environment } from '@tofu/shared/constants/environments'
// import { OneTrust } from '@tofu/shared/scripts/one-trust'

export const Tracking = () => (
  <>
    {/* {environment?.one_trust_id && <OneTrust />} */}
    {/* <!-- Google Tag Manager Data Layer --> */}
    <Script id='data-later'>window.dataLayer=window.dataLayer||[];</Script>
    <Script
      id='gtm-script'
      dangerouslySetInnerHTML={{
        __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${environment.gtm_id}');`
      }}
      defer
      type='text/javascript'
    />
  </>
)
