import styled from 'styled-components'
import { Box } from '@tofu/shared/ui/atoms/box'
import { breakpoints, color } from './variables'

const screenReaderText = `
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const HiddenInput = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;

  &[disabled] {
    cursor: default;
  }

  :focus + label {
    box-shadow: 0 0 0 2px ${color.button.outline};
  }
`

const VisuallyHidden = styled(Box)`
  ${screenReaderText}
`

const VisuallyHiddenMobile = styled(Box)`
  @media (max-width: ${breakpoints.tablet}px) {
    ${screenReaderText}
  }
`

const VisuallyHiddenTablet = styled(Box)`
  @media (max-width: ${breakpoints.desktop}px) {
    ${screenReaderText}
  }
`

export {
  HiddenInput,
  VisuallyHidden,
  VisuallyHiddenMobile,
  VisuallyHiddenTablet
}
