import { useGlobalAccountContentContext } from '@tofu/account/providers/global-account-content-provider'

import { TUseGlobalAccountContent } from './use-global-account-content.types'

export const useGlobalAccountContent: TUseGlobalAccountContent = () => {
  const { defaultPromoBanner } = useGlobalAccountContentContext()

  return {
    defaultPromoBanner
  }
}
