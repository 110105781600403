import { Button } from '@tofu/shared/ui/atoms/button'
import { Text } from '@tofu/shared/ui/atoms/text'
import { Link } from '@tofu/shop/ui/atoms/link'
import { TDrawerMobileMenuItemSecondary } from './drawer-mobile-menu-item-secondary.types'

export const DrawerMobileMenuItemSecondary: TDrawerMobileMenuItemSecondary = ({
  children,
  ...linkProps
}) => (
  <Button as={Link} variant='link' textDecoration='none' {...linkProps}>
    <Text as='span' textStyle='body' fontWeight='book' px={6}>
      {children}
    </Text>
  </Button>
)
