import dayjs from 'dayjs'
import { extrasList, mealsList } from './products'

const activeStatus = ['ACTIVE', 'ONE_TIME']

const findExtras = (items) =>
  items.filter((item) => extrasList.includes(item.product_type))

const findMeals = (items) =>
  items.filter((item) => mealsList.includes(item.product_type))

const findFutureOrders = (orders) => {
  // Check if order is before today
  const today = new Date()

  return orders.filter((order) => {
    const deliveryDate = new Date(order.deliver_at)
    return deliveryDate >= today ? order : null
  })
}

const findClosestOrder = (orders, direction) =>
  orders.reduce((a, b) => {
    const today = dayjs()
    const aDate = dayjs(a.deliver_at)
    const bDate = dayjs(b.deliver_at)

    const aDiff = today.diff(aDate, 'day')
    const bDiff = today.diff(bDate, 'day')

    if (direction === 'past') {
      return aDiff < bDiff ? a : b
    }
    return aDiff < bDiff ? b : a
  })

const findLastOrder = (orders) => {
  const today = dayjs()

  // Get past orders
  const pastOrders = orders.filter((order) => {
    const deliveryDate = new Date(order.deliver_at)
    return deliveryDate <= today ? order : null
  })

  // Get past order closest to today
  return pastOrders.length > 0
    ? findClosestOrder(pastOrders, 'past')
    : findClosestOrder(orders, 'future')
}

const findNextOrder = (orders) => {
  const futureOrders = findFutureOrders(orders)
  // Check for pending charge first, then if none pending fulfilment
  const upcoming = futureOrders.find(
    (order) => order.status === 'PENDING_CHARGE'
  )
  const dispatched = futureOrders.find(
    (order) => order.status === 'PENDING_FULFILMENT'
  )
  return dispatched || upcoming
}

const findOrder = (orderId, orders) => orders?.find((o) => o?.id === orderId)

const getItemsByType = (items) => {
  const breakfasts = items.filter(
    ({ product_type }) => product_type === 'BREAKFAST'
  )
  const sides = items.filter(({ product_type }) => product_type === 'SIDE')
  const smoothies = items.filter(
    ({ product_type }) => product_type === 'SMOOTHIE'
  )
  const traybakes = items.filter(
    ({ product_type }) => product_type === 'TRAYBAKE'
  )
  const treats = items.filter(({ product_type }) => product_type === 'TREAT')

  return {
    breakfasts,
    sides,
    smoothies,
    traybakes,
    treats
  }
}

const getTotalCount = (items) =>
  items.map(({ quantity }) => quantity || 0).reduce((a, b) => a + b)

const getExtraCount = (items) => {
  const extras = findExtras(items)
  return extras?.length > 0 ? getTotalCount(extras) : 0
}

const getMealCount = (items) => {
  const meals = findMeals(items)
  return meals?.length > 0 ? getTotalCount(meals) : 0
}

const getOrdersBySubscriptionId = (orders, subscriptionId) => {
  const id =
    typeof subscriptionId === 'string' ? Number(subscriptionId) : subscriptionId
  return orders?.filter(({ subscription_id }) => subscription_id === id)
}

export {
  activeStatus,
  findExtras,
  findMeals,
  findFutureOrders,
  findClosestOrder,
  findLastOrder,
  findNextOrder,
  findOrder,
  getItemsByType,
  getExtraCount,
  getMealCount,
  getOrdersBySubscriptionId,
  getTotalCount
}
