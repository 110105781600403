import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import { createContext, useCallback, useEffect, useState } from 'react'

import { logger } from '@tofu/shared/utils/sentry'

import Loader from '../components/common/loader'
import usePrevious from '../hooks/usePrevious'
import { getCustomersMe } from '../services/customers'
import { sendEvent } from '../utils/analytics'
import { useSession } from './session'
import { useStore } from './store'

const LoaderContext = createContext({})

const publicRoutes = new Set([
  '/forgot',
  '/login',
  '/register',
  '/reset',
  '/unauthenticated'
])

const LoaderProvider = ({ children }) => {
  const router = useRouter()

  const [loading, setLoading] = useState(true)
  const { customer, setCustomerData, updateAllOrders, updateSubscriptions } =
    useStore()

  const { data } = useSession()

  const handleRouteChange = () => setLoading(false)

  const loadData = useCallback(async () => {
    try {
      const result = await getCustomersMe()

      if (result && result.id) {
        await updateSubscriptions(result.id)
        await updateAllOrders()
        setCustomerData(result)
        if (router.asPath === '/') {
          router.push('/deliveries')
          router.events.on('routeChangeComplete', handleRouteChange)
        } else {
          setLoading(false)
        }
      }
    } catch (error) {
      router.push('/unauthenticated')
      router.events.on('routeChangeComplete', handleRouteChange)
      logger(error)
    }
  }, [router, setCustomerData, updateAllOrders, updateSubscriptions])

  useEffect(() => {
    // Don't initialise Amplitude or FS for admin users.
    if (!data?.admin) {
      if (customer?.id) {
        const sendAmplitudeId = () => {
          // Amplitude requires we always send minimum 5 characters as userID
          const idTo5chars = customer.id.toLocaleString('en-GB', {
            minimumIntegerDigits: 5,
            useGrouping: false
          })

          sendEvent('user.id', {
            user_id: idTo5chars
          })
        }
        sendAmplitudeId()
      }
      if (window?.FS) {
        const sendFsIdentify = async () => {
          const { email, first_name, id, last_name } = customer
          try {
            window.FS.identify(id, {
              displayName: `${first_name} ${last_name}`,
              email
            })
          } catch (error) {
            logger(error)
          }
        }

        sendFsIdentify()
      }
    }
  }, [customer, data])

  const prevData = usePrevious(data)

  useEffect(() => {
    if (data) {
      const { asPath, pathname, events, push } = router
      // Data is initialised as null, and returns {} if cookies are not detected.
      if (data?.userId !== prevData?.userId) {
        // Load customer data
        loadData()
      }

      if (publicRoutes.has(pathname)) {
        // Check if route is a public one, and if so disable loading animation and show page.
        setLoading(false)
      } else if (!data?.userId) {
        // Assume we're not authenticated, so redirect user to log page
        push(
          `/login${
            asPath !== '/' ? `?redirect=${encodeURIComponent(asPath)}` : ''
          }`
        )
        events.on('routeChangeComplete', handleRouteChange)
      }
    }
    // remove router deps to prevent infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loadData, prevData])

  return (
    <LoaderContext.Provider value={{}}>
      {loading ? <Loader /> : children}
    </LoaderContext.Provider>
  )
}

LoaderProvider.defaultProps = {
  children: null
}

LoaderProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export { LoaderContext, LoaderProvider }
