/* eslint-disable @typescript-eslint/no-empty-function */
import dayjs from 'dayjs'
import flatten from 'lodash/flatten'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'

import { getCustomer } from '@tofu/apps/account/services/customers'
import { getOrdersBySubscription } from '@tofu/apps/account/services/orders'
import { getSubscriptions } from '@tofu/apps/account/services/subscriptions'
import { activeStatus, findOrder } from '@tofu/apps/account/utils/orders'
import {
  findSubscription,
  getRecentActiveSub
} from '@tofu/apps/account/utils/subscriptions'

const StoreContext = createContext({
  customer: {},
  order: {},
  orders: [],
  subscription: {},
  subscriptions: [],
  updateCustomerData: () => {},
  updateSubscription: () => {}
})

const StoreProvider = ({ children }) => {
  const [customer, setCustomer] = useState({})
  const [order, setOrder] = useState({})
  const [orders, setOrders] = useState([])
  const [subscription, setSubscription] = useState({})
  const [subscriptions, setSubscriptions] = useState([])

  const router = useRouter()
  const { orderId, subscriptionId } = router.query

  const updateCustomerData = async (email) => {
    const result = await getCustomer(email)
    return setCustomer(result)
  }

  const updateSubscriptions = async (customerId) => {
    const result = await getSubscriptions(customerId)
    if (Array.isArray(result)) {
      const active = result.filter((sub) => activeStatus.includes(sub.status))
      const allCancelled = result.filter((sub) => sub.status === 'CANCELLED')
      setSubscriptions([...active, ...allCancelled])
    }
  }

  const getAllOrders = useCallback(async () => {
    if (subscriptions?.length > 0) {
      const minDate = dayjs().subtract(1, 'week').format('YYYY-MM-DD')
      const result = await Promise.all(
        subscriptions.map(async ({ id, status }) => {
          // Only set minDate for active subscriptions. For cancelled subscriptions, we need to fetch historic orders
          const subscriptionOrders = await getOrdersBySubscription(
            id,
            activeStatus.includes(status) ? minDate : undefined
          )
          return subscriptionOrders
        })
      )
      return setOrders(flatten(result))
    }
    return null
  }, [subscriptions])

  useEffect(() => {
    if (orders.length > 0 && orderId) {
      const result = findOrder(Number(orderId), orders)
      setOrder(result)
    }
  }, [orderId, orders])

  useEffect(() => {
    if (subscriptions.length > 0) {
      const recentSub = getRecentActiveSub(subscriptions) // If we can't get the subscriptionId via query params, fallback to the most recent active sub.

      const result = findSubscription(
        Number(subscriptionId || recentSub?.id),
        subscriptions
      )
      setSubscription(result)
    }
  }, [subscriptions, subscriptionId])

  useEffect(() => {
    getAllOrders()
  }, [getAllOrders])

  return (
    <StoreContext.Provider
      value={{
        customer,
        order,
        orders,
        setCustomerData: (data) => setCustomer(data),
        subscription,
        subscriptions,
        updateAllOrders: getAllOrders,
        updateCustomerData: (email) => updateCustomerData(email),
        updateSubscriptions: (customerId) => updateSubscriptions(customerId)
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

StoreProvider.defaultProps = {
  children: null
}

StoreProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

const useStore = () => {
  const store = useContext(StoreContext)
  return store
}

export { StoreContext, StoreProvider, useStore }
