import NextLink from 'next/link'
import { Link, ListItem } from '@chakra-ui/react'

import { TDesktopMenuItem } from '../desktop-menu.types'

export const DesktopMenuItem: TDesktopMenuItem = ({
  url,
  isActive,
  children
}) => (
  <ListItem position='relative'>
    <NextLink href={url} passHref>
      <Link
        display='block'
        py='12px'
        textStyle='body-lg'
        fontWeight='medium'
        data-testid={isActive ? 'active' : ''}
        textAlign='center'
        sx={{
          '&:hover': {
            textDecoration: 'none'
          },
          '&:after': {
            content: isActive ? '" "' : 'none',
            background: 'currentColor',
            width: '100%',
            height: '5px',
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto'
          }
        }}
      >
        {children}
      </Link>
    </NextLink>
  </ListItem>
)
