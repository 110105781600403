const config = {
  account_base_url: process.env.ACCOUNT_BASE_URL,
  api_base_url: process.env.API_BASE_URL,
  shop_base_url: process.env.SHOP_BASE_URL,
  environment: process.env.ENVIRONMENT,
  gtm_id: process.env.GTM_ID,
  prismic_repo: process.env.PRISMIC_REPO,
  release: process.env.NOW_GITHUB_COMMIT_SHA,
  sentry_dns: process.env.PEANUT_SENTRY_DNS,
  stripe_key: process.env.STRIPE_KEY,
  mentionme_url: process.env.MENTIONME_URL
}

export default config
