import { createContext, useContext } from 'react'

import {
  TGlobalAccountContentProvider,
  TGlobalAccountContentProps
} from './global-account-content-provider.types'

export const GlobalAccountContentContext =
  createContext<TGlobalAccountContentProps>({
    defaultPromoBanner: {
      text: []
    }
  })
export const useGlobalAccountContentContext = () =>
  useContext(GlobalAccountContentContext)

export const GlobalAccountContentProvider: TGlobalAccountContentProvider = ({
  children,
  defaultPromoBanner
}) => {
  return (
    <GlobalAccountContentContext.Provider value={{ defaultPromoBanner }}>
      {children}
    </GlobalAccountContentContext.Provider>
  )
}
