import template from 'lodash/template'
import isArray from 'lodash/fp/isArray'
import flow from 'lodash/fp/flow'
import toLower from 'lodash/fp/toLower'
import { getFromMap } from '@tofu/shared/utils/get-from-map'
import { TBuildScript, TGetFirstValueFromArray } from './inline-scripts.types'

import { scriptMap } from './inline-scripts.constants'

export const encodeToLower = flow(encodeURIComponent, toLower)

export const buildScript: TBuildScript = (scriptName, data) => {
  let src = ''

  try {
    src = getFromMap(scriptMap, scriptName)
  } catch {
    return null
  }

  // load the template with the src url
  const compiled = template(src)

  // add user data to the template
  return compiled(data)
}

export const getFirstValueFromArray: TGetFirstValueFromArray = (params) =>
  isArray(params) ? params[0] : params
