import { Flex } from '@tofu/shared/ui/atoms/flex'
import { VStack } from '@tofu/shared/ui/atoms/stack'
import { Link } from '@tofu/shop/ui/atoms/link'
import { ChevronRight } from '@tofu/shared/ui/atoms/icons'
import { TDrawerMobileMenuItemPrimary } from './drawer-mobile-menu-item-primary.types'

export const DrawerMobileMenuItemPrimary: TDrawerMobileMenuItemPrimary = ({
  children,
  ...linkProps
}) => (
  <Flex
    as={Link}
    alignItems='center'
    justifyContent='space-between'
    justifyItems='center'
    width='full'
    px={6}
    {...linkProps}
  >
    <VStack as='p' spacing={0} alignItems='start'>
      {children}
    </VStack>
    <ChevronRight />
  </Flex>
)
