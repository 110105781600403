import sortBy from 'lodash/sortBy'
import constants from '@tofu/apps/account/constants/env'
import { environment } from '@tofu/shared/constants/environments'

const alphabetise = (arr, param) => sortBy(arr, [param])

const isPreviewEnv = constants.environment === 'preview'
const isProdEnv = constants.environment === 'production'
const isDevEnv = constants.environment === 'development'

const endsWith = (str, suffix) =>
  str && str.includes(suffix, str.length - suffix.length)

const getShowMenuLink = (orderId, status) => {
  if (!orderId || !status) return ''

  if (status === 'PENDING_CHARGE') {
    return `${environment.shop_base_url}/account/pick/${orderId}`
  }

  if (status === 'PENDING_FULFILMENT') {
    return `/deliveries/${orderId}`
  }
}

export {
  alphabetise,
  endsWith,
  getShowMenuLink,
  isDevEnv,
  isPreviewEnv,
  isProdEnv
}
