import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import { Text } from '@tofu/shared/ui/atoms/text'
import { Flex } from '@tofu/shared/ui/atoms/flex'
import Icon from '@tofu/apps/account/components/common/icon'
import close from '@tofu/apps/account/icons/close'
import tick from '@tofu/apps/account/icons/tick'

import { color, fade } from '@tofu/apps/account/styles/variables'

const Container = styled(Flex)`
  animation: ${fade} 300ms linear;
`

const Feedback = ({ children, remove, type = 'error' }) => (
  <Container
    alignItems='center'
    bg={type === 'error' ? color.warningLight : color.lighterGreen}
    onClick={remove}
    p={'8px'}
    sx={{
      border: `1px solid ${type === 'error' ? color.warning : color.kale}`,
      cursor: 'pointer'
    }}
  >
    <Flex
      alignItems='center'
      height='36px'
      justifyContent='center'
      sx={{
        border: `2px solid ${type === 'error' ? color.warning : color.kale}`,
        borderRadius: '100%',
        lineHeight: '36px'
      }}
      width='36px'
    >
      <Icon
        fill={type === 'error' ? color.warning : color.kale}
        name={type === 'error' ? close : tick}
        width={20}
      />
    </Flex>
    <Flex
      flexDirection='column'
      fontSize='16px'
      pl={'16px'}
      width='calc(100% - 36px)'
    >
      <Text>{type === 'error' ? 'Uh oh...' : 'Hooray!'}</Text>
      <Text>{children}</Text>
    </Flex>
  </Container>
)

Feedback.defaultProps = {
  children: null,
  type: 'error'
}

Feedback.propTypes = {
  children: PropTypes.node,
  remove: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['error', 'info', 'success'])
}

export default Feedback
