/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useState } from 'react'
import delay from 'lodash/delay'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { breakpoints } from '@tofu/apps/account/styles/variables'
import Feedback from '../components/common/feedback'

const FeedbackContext = createContext({
  add: () => {}
})

const Wrapper = styled.div`
  position: fixed;
  bottom: 30px;
  left: 15px;
  z-index: 13;
  max-width: calc(100% - 120px);
  @media (min-width: ${breakpoints.desktop}px) {
    bottom: 60px;
  }
`

const FeedbackProvider = ({ children }) => {
  const [toggle, setToggle] = useState(false)
  const [text, setText] = useState(null)
  const [type, setType] = useState('error')

  const add = (feedbackType, message) => {
    setToggle(true)
    setText(message)
    setType(feedbackType)

    delay(() => setToggle(false), 4000)
  }

  return (
    <FeedbackContext.Provider
      value={{
        add: (feedbackType, message) => add(feedbackType, message)
      }}
    >
      {children}
      <Wrapper>
        {toggle && (
          <Feedback remove={() => setToggle(false)} type={type}>
            {text}
          </Feedback>
        )}
      </Wrapper>
    </FeedbackContext.Provider>
  )
}

FeedbackProvider.propTypes = {
  children: PropTypes.node.isRequired
}

const useFeedback = () => {
  const feedback = useContext(FeedbackContext)
  if (!feedback)
    throw new Error(
      'useFeedback must be used within a FeedbackContext component'
    )
  return feedback
}

export { FeedbackContext, FeedbackProvider, useFeedback }
